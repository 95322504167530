var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("InputUUIDProvider", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var uuid = ref.uuid
          return [
            _c(
              _vm.styleComponent,
              {
                ref: "activator",
                tag: "Component",
                attrs: {
                  height: _vm.height,
                  focused: _vm.isFocused,
                  error: _vm.isError,
                  "data-cy": _vm.dataCy,
                  disabled: _vm.disabled,
                  alignment: _vm.alignment,
                  size: _vm.size,
                  "details-label": _vm.informationLabel
                },
                on: { mousedown: _vm.onMouseDown },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function() {
                        return [
                          !_vm.disabled
                            ? _c("RichTextEditorMenuBubble", {
                                ref: "menuBubble",
                                attrs: { editor: _vm.editor }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "InputController",
                            [
                              _vm._t("prepend"),
                              _vm._v(" "),
                              _c(
                                "ResizeObserver",
                                { on: { resize: _vm.onResize } },
                                [
                                  _c(
                                    "div",
                                    { class: _vm.classes },
                                    [
                                      _c(
                                        "VerticalFixedScroll",
                                        [
                                          _c("EditorContent", {
                                            ref: "editorContent",
                                            staticClass:
                                              "rich-text-editor__content",
                                            attrs: { editor: _vm.editor }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.isSolidType && _vm.isFocused
                                        ? _c("RichTextEditorMenu", {
                                            attrs: {
                                              type: _vm.type,
                                              editor: _vm.editor,
                                              "editor-width": _vm.editorWidth
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm.label
                                ? _c("InputLabel", {
                                    attrs: {
                                      for: uuid,
                                      required: _vm.required,
                                      size: _vm.size,
                                      floating: true,
                                      focused: _vm.isFocused,
                                      disabled: _vm.disabled,
                                      error: _vm.isError,
                                      label: _vm.label
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._t("append"),
                              _vm._v(" "),
                              _vm.isError
                                ? _c("ErrorHint", {
                                    attrs: { hint: _vm.errorMessages }
                                  })
                                : _vm._e()
                            ],
                            2
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "details",
                      fn: function() {
                        return [_vm._t("details")]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              },
              [
                _vm._v(" "),
                !_vm.isSolidType && !_vm.disabled
                  ? _c("RichTextEditorMenu", {
                      attrs: {
                        type: _vm.type,
                        editor: _vm.editor,
                        "editor-width": _vm.editorWidth
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }