var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("InputUUIDProvider", {
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function(ref) {
            var uuid = ref.uuid
            return [
              _c(
                "div",
                { class: _vm.classes },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.radioValue,
                        expression: "radioValue"
                      }
                    ],
                    attrs: { id: uuid, type: "radio", name: _vm.name },
                    domProps: {
                      value: _vm.label,
                      checked: _vm._q(_vm.radioValue, _vm.label)
                    },
                    on: {
                      change: function($event) {
                        _vm.radioValue = _vm.label
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: uuid } }, [
                    _c("div", { staticClass: "radio-button__content" }, [
                      _c("div", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isSelected,
                            expression: "isSelected"
                          }
                        ],
                        staticClass: "radio-button__mark"
                      })
                    ]),
                    _vm._v(" "),
                    _vm.label
                      ? _c("span", {
                          staticClass: "radio-button__label",
                          domProps: { textContent: _vm._s(_vm.label) }
                        })
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm._t("append")
                ],
                2
              )
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }