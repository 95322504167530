var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ActionIconButton", {
    attrs: {
      options: _vm.options,
      size: _vm.tinySize,
      dismissible: false,
      theme: _vm.secondaryTheme
    },
    on: { focus: _vm.onFocus },
    scopedSlots: _vm._u([
      {
        key: "icon",
        fn: function(ref) {
          var color = ref.color
          return [
            _c("IconArrowDropdown", {
              attrs: { "fill-color": color, state: _vm.arrowIconState }
            })
          ]
        }
      },
      {
        key: "option",
        fn: function(ref) {
          var option = ref.option
          return [
            _c("RichTextEditorButton", {
              attrs: {
                extension: option,
                "is-active": _vm.isActive,
                commands: _vm.commands
              }
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }