var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "progress-list" },
    [
      _vm._l(_vm.datasets, function(item, index) {
        return [
          _c("ComplexProgressBar", {
            key: index,
            attrs: {
              value: item.value,
              "max-value": _vm.maxValue,
              color: item.color,
              label: item.label
            }
          }),
          _vm._v(" "),
          _c("span", {
            key: "divider-" + index,
            staticClass: "progress-list__data-label",
            domProps: { textContent: _vm._s(_vm.labels[index]) }
          })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }