var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("IconButton", {
    attrs: { size: _vm.tinySize, theme: _vm.secondaryTheme },
    on: { mouseenter: _vm.onMouseEnter, mouseleave: _vm.onMouseLeave },
    nativeOn: {
      mousedown: function($event) {
        $event.preventDefault()
        return _vm.commandCallback.apply(null, arguments)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "icon",
        fn: function() {
          return [
            _c(_vm.iconComponent, {
              tag: "Component",
              attrs: { width: 18, height: 18, "fill-color": _vm.iconFillColor }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }