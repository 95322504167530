var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ResizeObserver", { on: { resize: _vm.onResize } }, [
    _c("div", { staticClass: "complex-progress-bar" }, [
      _c("span", {
        staticClass: "complex-progress-bar__label",
        domProps: { textContent: _vm._s(_vm.label) }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "complex-progress-bar__progress" }, [
        _c("div", {
          ref: "value",
          staticClass: "complex-progress-bar__value",
          style: _vm.valueStyles
        }),
        _vm._v(" "),
        (_vm.value <= _vm.maxValue && _vm.value > 0) || _vm.value > _vm.maxValue
          ? _c("div", {
              ref: "progressDivider",
              staticClass: "complex-progress-bar__progress-divider"
            })
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }