var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("EditorMenuBar", {
    attrs: { editor: _vm.editor },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var commands = ref.commands
          var isActive = ref.isActive
          return [
            _c(
              "div",
              { class: _vm.classes, style: _vm.styles },
              [
                _vm._l(_vm.visibleExtensions, function(extension, index) {
                  return _c("RichTextEditorButton", {
                    key: extension.name + "|" + index,
                    attrs: {
                      extension: extension,
                      "is-active": isActive,
                      commands: commands
                    }
                  })
                }),
                _vm._v(" "),
                _vm.hiddenExtensions.length
                  ? _c("RichTextEditorActionIconButton", {
                      attrs: {
                        options: _vm.hiddenExtensions,
                        "is-active": isActive,
                        commands: commands
                      }
                    })
                  : _vm._e()
              ],
              2
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }