var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    class: _vm.classes,
    style: _vm.styles,
    on: {
      mousedown: _vm.onInitResize,
      mouseenter: _vm.onMouseEnter,
      mouseleave: _vm.onMouseLeave
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }