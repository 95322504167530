var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("EditorMenuBubble", {
    attrs: { editor: _vm.editor },
    on: { hide: _vm.onHideLinkMenu },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var commands = ref.commands
          var isActive = ref.isActive
          var getMarkAttrs = ref.getMarkAttrs
          var menu = ref.menu
          return [
            _c(
              "div",
              {
                staticClass: "menububble",
                class: { "menububble--active": menu.isActive },
                style:
                  "left: " + menu.left + "px; bottom: " + menu.bottom + "px;"
              },
              [
                _vm.linkMenuIsActive
                  ? _c(
                      "form",
                      {
                        staticClass: "menububble__form",
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.setLinkUrl(commands.link, _vm.linkUrl)
                          }
                        }
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.linkUrl,
                              expression: "linkUrl"
                            }
                          ],
                          ref: "linkInput",
                          staticClass: "menububble__input",
                          attrs: { type: "text", placeholder: "https://" },
                          domProps: { value: _vm.linkUrl },
                          on: {
                            keydown: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k($event.keyCode, "esc", 27, $event.key, [
                                  "Esc",
                                  "Escape"
                                ])
                              ) {
                                return null
                              }
                              return _vm.onHideLinkMenu.apply(null, arguments)
                            },
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.linkUrl = $event.target.value
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "Button",
                          {
                            staticClass: "menububble__remove-button",
                            attrs: { type: "button" },
                            on: {
                              mouseenter: function($event) {
                                _vm.isHovered = true
                              },
                              mouseleave: function($event) {
                                _vm.isHovered = false
                              }
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.setLinkUrl(commands.link, null)
                              }
                            }
                          },
                          [
                            _c("IconFilledClose", {
                              attrs: { "fill-color": _vm.removeLinkFillColor }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _c(
                      "Button",
                      {
                        staticClass: "menububble__button",
                        nativeOn: {
                          click: function($event) {
                            return _vm.onShowLinkMenu(getMarkAttrs)
                          }
                        }
                      },
                      [
                        _c("IconLink", {
                          attrs: { "fill-color": _vm.whiteColor }
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(isActive.link() ? "Update Link" : "Add Link")
                          )
                        ])
                      ],
                      1
                    )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }