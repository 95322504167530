var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "circle-progress-bar" },
    [
      _c(
        "svg",
        {
          staticClass: "circle-progress-bar__svg",
          attrs: { height: _vm.size, width: _vm.size }
        },
        [
          _c("circle", {
            attrs: {
              stroke: _vm.whitesmokeStrokeColor,
              fill: "transparent",
              "stroke-width": _vm.stroke,
              r: _vm.normalizedRadius,
              cx: _vm.radius,
              cy: _vm.radius
            }
          }),
          _vm._v(" "),
          _c("circle", {
            staticClass: "circle-progress-bar__progress",
            style: { strokeDashoffset: _vm.strokeDashoffset },
            attrs: {
              stroke: _vm.strokeColor,
              fill: "transparent",
              "stroke-dasharray": _vm.strokeDasharray,
              "stroke-width": _vm.stroke,
              r: _vm.normalizedRadius,
              cx: _vm.radius,
              cy: _vm.radius
            }
          })
        ]
      ),
      _vm._v(" "),
      _c("span", {
        staticClass: "circle-progress-bar__title",
        domProps: { textContent: _vm._s(_vm.percentProgress) }
      }),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }